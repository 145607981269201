.carousel {
  position: relative;
  height: 600px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
}

@media screen and (max-width: 800px) {
  .carousel {
    height: 300px;
  }
  .img {
    background-size: contain;
  }
}
