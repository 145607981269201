.card {
  font-family: "Titillium Web", sans-serif;
}
.textarea {
  height: 100px;
  margin-top: 5px;
  width: 100%;
  padding: 8px;
  background-color: lightgray;
  border-radius: 10px;
  resize: none;
}

.title {
  font-weight: bolder;
  color: white;
}
