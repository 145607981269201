.App {
  text-align: center;
  font-family: "Titillium Web", sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.react-chatbot-kit-chat-input {
  width: 100%;
  padding: 12.5px;
  border: none;
  font-size: 0.85rem;
  border-top: 1px solid #d8d8d8;
  border-bottom-left-radius: 5px;
  color: black;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 800px) {
  table {
    width: 100%;
  }
  thead {
    display: none;
  }
  tr:nth-of-type(2n) {
    background-color: inherit;
  }
  tr td:first-child {
    background: #f0f0f0;
    font-weight: bold;
    font-size: 1.3em;
    text-align: center;
  }
  tbody td {
    display: block;
    text-align: center;
  }
  tbody td:before {
    content: attr(data-th);
    display: block;
    text-align: center;
  }
}
