.addButton {
  cursor: pointer;
  color: white;
  border-radius: 5px;
  background-color: grey;
  margin-top: 100px;
  height: max-content;
  width: max-content;
  font-weight: bold;
  font-size: 1.2em;
  padding: 2px 10px;
  font-family: "Titillium Web", sans-serif;
}

.addButton:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.dialogContainer {
  border-radius: 100px;
}

.dialogTitle {
  color: green;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 800;
  font-size: small;
  letter-spacing: 1.1px;
}
.activityContainer {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.activityLabel {
  margin-right: 10px;
  font-weight: bold;
}

.activitySelect {
  width: 100%;
  padding: 8px 12px;
  border-radius: 5px;
  border: 1px solid #ccc;
  outline: none;
  transition: border-color 0.3s ease;
}
.clasesErrors {
  position: absolute;
  bottom: -20px;
  color: red;
  font-size: 12px;
}

.activitySelect:focus {
  border-color: #888;
}

.textareaContainer {
  position: relative;
  margin-bottom: 20px;
}
.textareaError {
  position: absolute;
  bottom: -20px;
  color: red;
  font-size: 12px;
}

.textareaLabel {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.textarea {
  width: 100%;
  height: 120px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  outline: none;
  transition: border-color 0.3s ease;
}

.textarea:focus {
  border-color: #888;
}

.dialogActions {
  justify-content: flex-end;
  margin-top: 20px;
}

.dialogActions > button {
  color: white;
  margin-left: 10px;
  padding: 8px 12px;
  border-radius: 5px;
  font-weight: bold;
  background-color: grey;
}

.dialogActions > button:first-child {
  margin-left: 0;
}

.dialogActions > button:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
}

.dialogActions > button:disabled {
  cursor: not-allowed;
  background-color: #ccc;
  color: #888;
}
