.chat {
  display: none;
  position: fixed;
  top: 120px;
  right: 100px;
}
.btnContainer {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 100px;
  right: 40px;
  width: 75px;
  height: 75px;
  border: 5px solid rgb(10, 10, 10, 0.6);
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 50%;
}
.span {
  color: rgb(10, 10, 10, 0.6);
  font-size: 0.8em;
}
@media screen and (max-width: 800px) {
  .span {
    display: none;
  }
  .btnContainer {
    top: 90%;
    width: 60px;
    height: 60px;
  }
  .chat {
    top: 35%;
  }
}
