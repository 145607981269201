.cardBody {
  display: flex;
  box-shadow: 1px 1px 3px black;
  flex-direction: column;
  border-radius: 15px;
  background-color: white;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  font-family: "Titillium Web", sans-serif;
  background-color: #28242b;
}

.image {
  width: 100%;
  border-radius: 15px 15px 0px 0px;
}
.text {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 1.2em;
  font-weight: bold;
  color: white;
}
.list {
  list-style: none;
  display: flex;
  flex-direction: column;
  font-size: 1em;
  color: white;
}

.btn {
  padding: 5px 50px;
}
