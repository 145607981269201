.img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  cursor: pointer;
  margin-right: 30px;
}

.innerImg {
  --color: #28242b; /* the color of the border */
  --border: 5px; /* the thickness of the border */
  --offset: 30px; /* control the movement of the border */
  --gap: 5px; /* the gap on hover */

  border-radius: 50%;
  cursor: pointer;
  padding: calc(var(--border) + var(--gap));
  border: var(--offset) solid #0000;
  --_m: radial-gradient(
    50% 50%,
    #000 calc(100% - var(--offset)),
    #0000 calc(100% - var(--border))
  );
  -webkit-mask: var(--_m);
  mask: var(--_m);
  --_g: #0000 calc(99% - var(--border)),
    var(--color) calc(100% - var(--border)) 99%, #0000;
  --_s: var(--offset);
  --_r: 100% 100% at;
  background: radial-gradient(var(--_r) 0 0, var(--_g)) calc(100% + var(--_s))
      calc(100% + var(--_s)),
    radial-gradient(var(--_r) 100% 0, var(--_g)) calc(0% - var(--_s))
      calc(100% + var(--_s)),
    radial-gradient(var(--_r) 0 100%, var(--_g)) calc(100% + var(--_s))
      calc(0% - var(--_s)),
    radial-gradient(var(--_r) 100% 100%, var(--_g)) calc(0% - var(--_s))
      calc(0% - var(--_s));
  background-size: 50% 50%;
  background-repeat: no-repeat;
  transition: 0.4s;
}
img:hover {
  --_s: 0px;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.imgMemb {
  width: 150px;
}
@media screen and (max-width: 800px) {
  .img {
    width: 20px;
    height: 20px;
  }
}
