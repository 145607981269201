.containerReviews {
  width: 100%;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Define 4 columnas de igual tamaño */
  grid-gap: 1rem; /* Espacio entre las tarjetas */
}
.title {
  font-size: 5em;
  width: 100%;
  text-align: center;
  color: white;
  background-color: #28242b;
  margin-top: 100px;
  margin-bottom: 50px;
}

.postBoton {
  align-items: center;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1250px) {
  .container {
    grid-template-columns: repeat(3, 1fr);
  }
  .postBoton {
    font-size: 0.5em;
  }
}
@media screen and (max-width: 850px) {
  .title {
    font-size: 2em;
  }
  .container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 500px) {
  .container {
    grid-template-columns: repeat(1, 1fr);
  }
}
