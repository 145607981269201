.Button {
  width: fit-content;
}
.body {
  font-family: "Titillium Web", sans-serif;
  background-color: white;
  width: 100%;
  border-width: 1px;
  border-radius: 20px;
  height: max-content;
}

.threeTier {
  font-family: "Titillium Web", sans-serif;
  margin-left: 50px;
  margin-right: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.title {
  font-family: "Titillium Web", sans-serif;
  font-size: 3em;
  font-weight: bold;
}
.subtitle {
  font-family: "Titillium Web", sans-serif;
  font-size: 1.5em;
}
.topCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
.bottomCard {
  padding-top: 30px;
  padding-bottom: 10px;
}

@media screen and (max-width: 800px) {
  .title {
    font-family: "Titillium Web", sans-serif;
    font-size: 1.5em;
    font-weight: bold;
  }
  .subtitle {
    font-family: "Titillium Web", sans-serif;
    font-size: 1em;
  }

  .threeTier {
    margin-left: 20%;
    width: 60%;
  }
}
