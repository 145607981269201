.body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Titillium Web", sans-serif;
  background-color: #aaa8ac;
}

.container {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
}
.title {
  font-size: 5em;
  width: 100%;
  text-align: center;
  color: white;
  background-color: #28242b;
}
.pagesContainer {
  display: flex;
  grid-gap: 1em;
  grid-template-rows: repeat(1, 1fr);
  margin-top: 10px;
  margin-bottom: 10px;
}
@media screen and (max-width: 800px) {
  .title {
    font-size: 2em;
  }
  .button {
    font-size: 0.8em;
  }
  .pages {
    font-size: 0.8em;
  }
}
