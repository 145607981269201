.learning_options_container {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.learning_option_button {
  padding: 0.5rem;
  border-radius: 25px;
  background: transparent;
  border: 1px solid rgb(131, 134, 131);
  margin: 3px;
  color: black;
}
