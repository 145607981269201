.imgContainer {
  width: 100%;
  height: 100%;
  background-color: #aaa8ac;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.4%;
}
.imagen {
  width: 65%;
  object-fit: cover;
  border-radius: 10px;
  border: 1px solid #ccc;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
}
@media screen and (max-width: 1550px) {
  .imgContainer {
    padding-bottom: 90%;
  }
}
