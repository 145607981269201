.input {
  margin-right: 50%;
  width: 20%;
  font-family: "Titillium Web", sans-serif;
}
.DashboardAdmin_container__H790\+ {
  margin: 0;
}
.logoMem {
  margin-left: 25px;
}
.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0px 0px 0px 0px;
}
.containerListado {
  font-size: 1.5em;
}
.titleContainer {
  display: flex;
  width: 80%;
  justify-content: space-around;
  align-items: center;
  margin-top: 50px;
}
.titleestadisticas {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.title {
  font-size: 5em;
  font-weight: bolder;
  font-family: "Titillium Web", sans-serif;
}
.refreshButton {
  border: none;
  font-family: "Titillium Web", sans-serif;
}
.clientSearch {
  height: 100%;
  display: flex;
  align-items: start;
  justify-content: space-around;
  padding: 1em;
  width: 100%;
  font-family: "Titillium Web", sans-serif;
}

.tabla {
  border-collapse: collapse;
  font-family: "Titillium Web", sans-serif;
}

.tabla th,
.tabla td {
  padding: 8px;
  text-align: center;
  border: 1px solid #ddd;
  font-family: "Titillium Web", sans-serif;
}

.tabla th {
  background-color: #f2f2f2;
}

.button {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-family: "Titillium Web", sans-serif;
}

.button3 {
  margin-left: 10%;
  margin-top: 2%;
  border-radius: 30%;
  font-size: 1.5rem;
  font-weight: bold;
  background-color: #160b8d;
  color: rgb(255, 255, 255);
  font-family: "Titillium Web", sans-serif;
}

.refreshButton:focus {
  outline: none;
}

.customButton:active {
  background-color: #3e8e41;
}

.pagesContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5em;
}
.stadistics {
  font-size: 1.8em;
  font-family: "Titillium Web", sans-serif;
}
.stadisticsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.circulo {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 800px) {
  .container {
    min-height: 100vh;
    display: block;
  }
  .titleContainer {
    width: auto;
    font-size: 9px;
    flex-direction: column;
    margin-top: 0px;
  }
  .titleestadisticas {
    margin: 0;
    padding: 0px 0px 0px 0px;
  }
  .stadisticsContainer {
    flex-direction: column;
  }
  .circulo {
    display: block;
  }
  .user:nth-of-type(1):before {
    content: "NAME";
  }
  .email:nth-of-type(2):before {
    content: "EMAIL";
  }
  .suscription:nth-of-type(3):before {
    content: "SUSCRIPTION";
  }
  .isBanned:nth-of-type(4):before {
    content: "IS BANNED";
  }
  .isAdmin:nth-of-type(5):before {
    content: "IS ADMIN";
  }
  .buttonO:nth-of-type(6):before {
    content: "ACTIONS";
  }
  .class:nth-of-type(1):before {
    content: "CLASS";
  }
  .class:nth-of-type(3):before {
    content: "CLASS";
  }
  .days:nth-of-type(2):before {
    content: "DAYS";
  }
  .schudle:nth-of-type(3):before {
    content: "START TIME";
  }
  .comment:nth-of-type(4):before {
    content: "COMMENT";
  }
  .isBanned:nth-of-type(5):before {
    content: "IS BANNED";
  }
  .buttonO:nth-of-type(4):before {
    content: "ACTIONS";
  }
}
