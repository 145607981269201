.boxes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1em;
  padding: 2em;
  background-color: #aaaaaa;
  width: 90%;
  margin-left: 5%;
}

.boxMargin {
  background-color: #f1f1f1;
  padding: 10px;
  height: 100%;
  width: 100%;
}

.classImage:hover {
  cursor: pointer;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.overlayText {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}

@media screen and (max-width: 800px) {
  .boxes {
    width: 100%;
    height: 100%;
    display: block;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .boxMargin {
    width: 90%;
    margin-top: 10px;
  }
}
