@property --angle {
  syntax: "<angle>";
  initial-value: 90deg;
  inherits: true;
}

@property --gradX {
  syntax: "<percentage>";
  initial-value: 50%;
  inherits: true;
}

@property --gradY {
  syntax: "<percentage>";
  initial-value: 0%;
  inherits: true;
}

:root {
  --d: 2500ms;
  --angle: 90deg;
  --gradX: 100%;
  --gradY: 50%;
  --c1: rgba(168, 239, 255, 1);
  --c2: rgba(168, 239, 255, 0.1);
}
@keyframes borderRadial {
  20% {
    --gradX: 100%;
    --gradY: 50%;
  }
  40% {
    --gradX: 100%;
    --gradY: 100%;
  }
  60% {
    --gradX: 50%;
    --gradY: 100%;
  }
  80% {
    --gradX: 0%;
    --gradY: 50%;
  }
  100% {
    --gradX: 50%;
    --gradY: 0%;
  }
}
.nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: rgb(10, 10, 10, 0.9);
  border-bottom: 0.35rem solid;
  height: 60px;
  border-image: radial-gradient(
      ellipse at var(--gradX) var(--gradY),
      var(--c1),
      var(--c1) 10%,
      var(--c2) 40%
    )
    30;
  animation: borderRadial var(--d) linear infinite forwards;
  font-family: "Titillium Web", sans-serif;
}
.logo {
  width: 35px;
  height: 35px;
  background-color: white;
  border-radius: 50%;
  border: 2px solid white;
  margin-left: 30px;
}

.itemContainer {
  width: 100%;
  font-size: 1.2em;
  font-weight: bolder;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.link {
  color: white;
  text-decoration: none;
}
.linkActive {
  color: orange;
  text-decoration: none;
}
.link:hover {
  color: orange;
  text-decoration: none;
}

@media screen and (max-width: 800px) {
  .itemContainer {
    font-size: 0.5em;
  }
  .button {
    font-size: 0.4em;
  }
  .logo {
    width: 30px;
    height: 30px;
  }
}
