.home {
  background-color: #aaa8ac;
  font-family: "Titillium Web", sans-serif;
}
.titleContainer {
  font-size: 5em;
  width: 100%;
  text-align: center;
  color: white;
  background-color: #28242b;
}
@media screen and (max-width: 800px) {
  .titleContainer {
    font-size: 2em;
  }
}
