.cardsContainer {
  display: grid;
  margin: 0 auto;
  grid-gap: 6rem;
  grid-template-columns: repeat(3, 1fr);
  font-family: "Titillium Web", sans-serif;
}
@media screen and (max-width: 1250px) {
  .cardsContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 800px) {
  .cardsContainer {
    grid-template-columns: repeat(1, 1fr);
  }
}
