:root {
  --hue: 223;
  --bg: hsl(var(--hue), 90%, 90%);
  --fg: hsl(var(--hue), 90%, 10%);
  --primary: hsl(var(--hue), 90%, 50%);
  --trans-dur: 0.3s;
}
.bike {
  display: block;
  margin: auto;
  width: 16em;
  height: auto;
}
.bike__body,
.bike__front,
.bike__handlebars,
.bike__pedals,
.bike__pedals_spin,
.bike__seat,
.bike__spokes,
.bike__spokes_spin,
.bike__tire {
  animation: bikeBody 3s ease-in-out infinite;
  stroke: var(--primary);
  transition: stroke var(--trans-dur);
}
.bike__front {
  animation-name: bikeFront;
}
.bike__handlebars {
  animation-name: bikeHandlebars;
}
.bike__pedals {
  animation-name: bikePedals;
}
.bike__pedals_spin {
  animation-name: bikePedalsSpin;
}
.bike__seat {
  animation-name: bikeSeat;
}
.bike__spokes,
.bike__tire {
  stroke: currentColor;
}
.bike__spokes {
  animation-name: bikeSpokes;
}
.bike__spokes_spin {
  animation-name: bikeSpokesSpin;
}
.bike__tire {
  animation-name: bikeTire;
}

/* Animations */
@keyframes bikeBody {
  from {
    stroke-dashoffset: 79;
  }
  33%,
  67% {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: -79;
  }
}
@keyframes bikeFront {
  from {
    stroke-dashoffset: 19;
  }
  33%,
  67% {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: -19;
  }
}
@keyframes bikeHandlebars {
  from {
    stroke-dashoffset: 10;
  }
  33%,
  67% {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: -10;
  }
}
@keyframes bikePedals {
  from {
    animation-timing-function: ease-in;
    stroke-dashoffset: -25.133;
  }
  33%,
  67% {
    animation-timing-function: ease-out;
    stroke-dashoffset: -21.991;
  }
  to {
    stroke-dashoffset: -25.133;
  }
}
@keyframes bikePedalsSpin {
  from {
    transform: rotate(0.1875turn);
  }
  to {
    transform: rotate(3.1875turn);
  }
}
@keyframes bikeSeat {
  from {
    stroke-dashoffset: 5;
  }
  33%,
  67% {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: -5;
  }
}
@keyframes bikeSpokes {
  from {
    animation-timing-function: ease-in;
    stroke-dashoffset: -31.416;
  }
  33%,
  67% {
    animation-timing-function: ease-out;
    stroke-dashoffset: -23.562;
  }
  to {
    stroke-dashoffset: -31.416;
  }
}
@keyframes bikeSpokesSpin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(3turn);
  }
}
@keyframes bikeTire {
  from {
    animation-timing-function: ease-in;
    stroke-dashoffset: 56.549;
    transform: rotate(0);
  }
  33% {
    stroke-dashoffset: 0;
    transform: rotate(0.33turn);
  }
  67% {
    animation-timing-function: ease-out;
    stroke-dashoffset: 0;
    transform: rotate(0.67turn);
  }
  to {
    stroke-dashoffset: -56.549;
    transform: rotate(1turn);
  }
}
