.body {
  font-family: "Titillium Web", sans-serif;
  background-color: #aaa8ac;
}

.film {
  border: 2px solid black;
  border-radius: 5px;
  transform: scale(1.15);
  margin: 20px;
}
.filterContainer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 90%;
}

.paginate {
  size: 30rem;
  padding-left: '1%';
}

.img {
  height: 600px;
  width: 100%;
}
.title {
  font-size: 5em;
  width: 100%;
  text-align: center;
  color: white;
  background-color: #28242b;
}
.pagesContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 0.5em;
  padding-bottom: 0.5%;
}
@media screen and (max-width: 1050px) {
  .title {
    font-size: 2em;
  }
  .img {
    height: 300px;
  }
  .button {
    font-size: 0.8em;
  }
  .film {
    font-size: 0.8em;
  }
  .pagesContainer {
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: repeat(10, 1fr);
  }
}
@media screen and (max-width: 900px) {

  .film {
    width: 50%;
  }


  .filterContainer {
    display: grid;
    grid-gap: 1em;
    grid-template-columns: repeat(2, 1fr);
    width: 90% ;
  }
}
@media screen and (max-width: 900px) {
  .pagesContainer {
    grid-template-columns: repeat(5, 1fr);
  }
}

