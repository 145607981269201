.body {
  font-family: "Titillium Web", sans-serif;
}
.title {
  font-size: 5em;
  width: 100%;
  text-align: center;
  color: white;
  background-color: #28242b;
}
.card {
  display: grid;
  padding: 40px;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  background: rgb(173, 170, 170);
}
.rutina {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.img {
  width: 80%;
  box-shadow: 2px 2px 5px white;
  border-radius: 15px;
}

.titleCard {
  font-size: 3em;
  font-weight: bolder;
  background-color: #28242b;
  width: 100%;
  text-align: center;
  color: white;
  margin-bottom: 10px;
}
.subTitle {
  font-size: 2em;
  font-weight: bolder;
  background-color: #28242b;
  width: 100%;
  text-align: center;
  color: white;
  margin-bottom: 10px;
}
.grupoMuscular {
  font-size: 1.5em;
  font-weight: bold;
}
.instructions {
  font-size: 1.2em;
  text-align: center;
  padding: 5px;
}

@media screen and (max-width: 1050px) {
  .title {
    font-size: 2em;
  }
  .card {
    grid-template-columns: repeat(1, 1fr);
  }
  .titleCard {
    font-size: 1em;
  }
  .subTitle {
    font-size: 0.8em;
  }
  .img {
    width: 60%;
  }
  .grupoMuscular {
    font-size: 1em;
  }
  .instructions {
    font-size: 0.8em;
  }
}
